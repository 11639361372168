.panelTitle {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 8px 0;
  font-weight: normal;
  color: var(--text-color);
  position: absolute;
  top: 0;
  left: 10px;
}

.aside {
  background-color: var(--ide-aside-bg);
  width: 300px;
  height: 100%;
  padding: 0 10px;
  position: relative;
  flex: 0 0 auto;
}

.asideContent {
  position: absolute;
  top: 34px;
  bottom: 0;
  overflow-y: auto;
  right: 0;
  left: 0;
  padding: 0 10px;
}

.inputControl {
  margin-bottom: 10px;
  width: 100%;
}
